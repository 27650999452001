/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.notify-listItem {
  padding: 8px 20px;
  border-bottom: 0 none !important;
}
.notify-listItem .ant-list-item-meta {
  align-items: center;
}
.notify-listItem .ant-list-item-meta-avatar {
  margin-right: 12px;
}
[dir=rtl] .notify-listItem .ant-list-item-meta-avatar {
  margin-right: 0;
  margin-left: 12px;
}
@media screen and (min-width: 768px) {
  .notify-listItem .ant-list-item-meta-avatar {
    margin-right: 16px;
  }
  [dir=rtl] .notify-listItem .ant-list-item-meta-avatar {
    margin-right: 0;
    margin-left: 16px;
  }
}
.notify-listItem .ant-list-item-meta-title {
  margin-bottom: 2px;
}
.notify-message-avatar {
  width: 48px;
  height: 48px;
  background-color: orange;
}
.ant-avatar-string {
  transform: scale(1) translateX(-50%);
  font-size: 30px;
  background-color: orange;
  width: 40px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
